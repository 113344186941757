import React from 'react'
import {Link} from 'gatsby'
import image from '../images/arrow-right.svg'

const Navlink = ({title, url}) => (
    <Link to={url} className="co-paper co-navlink">
        <h3>{title}</h3>
        <img src={image} alt="arrow" />
    </Link>
)

export default Navlink