import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'
import Navlink from '../../ui/Navlink'

const index = () => {

    // const developers = [
    //     {
    //         title: "Staff Frontend Engineer, Dashboard Experience",
    //         url: "/"
    //     },
    //     {
    //         title: "Staff Frontend Engineer, Dashboard Experience",
    //         url: "/"
    //     }
    // ]

  return (
    <>
    <SEO title="Vacancies - Digimeth.net"/>
    <Layout>
            <article className='co-page'>
                <section className="typical__padding">
                    <div className="section-container">
                        <h2 className="section-title">
                            Careers
                        </h2>
                     
                        <div className="co-vacancies--grid">
                            <h3 className='co-vacancies--grid--title'>
                            Developers
                            </h3>
                            <ul className='co-vacancies--list'>
                                <li>
                                   <Navlink title="Network Engineer" url="/vacancies/network-engineer"/>
                                </li>
                                <li>
                                    <Navlink title="Node.js Developer" url="/vacancies/node-js-developer"/>
                                </li>
                                <li>
                                    <Navlink title="Senior PHP Developer" url="/vacancies/senior-php-developer"/>
                                </li>
                            </ul>
                            <h3 className='co-vacancies--list--title'>
                            Product Team
                            </h3>
                            <ul className='co-vacancies--list'>
                                <li>
                                   <Navlink title="Enterprise Deal Desk Analyst" url="/vacancies/enterprise-deal-desk-analyst"/>
                                </li>
                                <li>
                                   <Navlink title="Product Design" url="/vacancies/product-design"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
                <Contact/>
            </article>
        </Layout>
    </>
  )
}

export default index